import { useOutletContext } from "react-router-dom";
import { useRef, useState, useEffect } from 'react';
import { MdHandyman } from 'react-icons/md'
import { MdCloud } from 'react-icons/md'
import { MdReportProblem } from 'react-icons/md'
import { SuperSEO } from "react-super-seo";

const Importance = {
	0: 'n/a',
	1: 'low',
	2: 'medium',
	3: 'high'
}

const Type = {
	0: <MdReportProblem />,
	weather: <MdCloud />,
	maintenance: <MdHandyman />,
}

const Notifications = () => {

	const [data, setData] = useOutletContext()
	const [notifications, setNotifications] = useState()

	useEffect(() => {
		setNotifications(data.pass_data.notifications)
	}, [data])

	const formatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric" }
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	return (
		<section className="contentWrapper" id="notifications">

			<SuperSEO
			  title={process.env.REACT_APP_SEO_NOTIFICATIONS_TITLE}
			  description={process.env.REACT_APP_SEO_NOTIFICATIONS_DESCRIPTION}
			/>

			<section className="primary">
				<h2>Notifications</h2>
				{notifications?.length ?
				<ul>
				{notifications.map((notification, i) =>
					<li key={i}>
						<h3 className={Importance[notification.notification_importance] || Importance[0]}><span>{notification.notification_title}</span><span className="icon">{Type[notification.notification_type] || Type[0]}</span></h3>
						<div className="content">
							<p className="details"><strong>Details:</strong> {notification.notification_description}</p>
							<p className="type"><strong>Type:</strong> {notification.notification_type ? notification.notification_type : 'general'}</p>
							<p className="issued"><strong>Issued:</strong> {formatDate(notification.updated_at)}</p>
							<p className="expires"><strong>Expires:</strong> {notification.expires ? formatDate(notification.expires) : "N/A"}</p>
							<p className="importance"><strong>Importance:</strong> {Importance[notification.notification_importance] || Importance[0]}</p>
						</div>
					</li>
				)}
				</ul>
				:
				<p>No notifications at this time</p>
				}
			</section>

			<section className="secondary">
			</section>
		</section>
	)
}

export default Notifications