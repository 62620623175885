import { useOutletContext } from "react-router-dom";
import { useRef, useState, useEffect } from 'react';
import { SuperSEO } from "react-super-seo";

const News = () => {

	const [data, setData] = useOutletContext()
	const [news, setNews] = useState()

	useEffect(() => {
		setNews(data.news)
	}, [data])

	const formatDate = (dateString) => {
	  const options = { month: "long", day: "numeric", year: "numeric" }
	  return new Date(dateString).toLocaleDateString(undefined, options)
	}

	return (
		<section className="contentWrapper" id="news">

			<SuperSEO
			  title={process.env.REACT_APP_SEO_NEWS_TITLE}
			  description={process.env.REACT_APP_SEO_NEWS_DESCRIPTION}
			/>

			<section className="primary">
				<h2>News & Updates	</h2>
				{news?.length ?
					<ul>
						{news.map((post, i) =>
							<li key={i}>
								<h4 className="title">{post.post_title}</h4>
								<p className="post_date">{formatDate(post.updated_at)}</p>
								<div className="content">{post.post_content}</div>
								<p className="author">{post.posted_by}</p>
							</li>
						)}
					</ul>
					:
					<p>No news available</p>
				}
			</section>

			<section className="secondary">
			</section>
		</section>
	)
}

export default News