import { SuperSEO } from "react-super-seo";

const About = () => {
	return (
		<section className="contentWrapper" id="weather">

			<SuperSEO
			  title={process.env.REACT_APP_SEO_WEATHER_TITLE}
			  description={process.env.REACT_APP_SEO_WEATHER_DESCRIPTION}
			/>

			<section className="primary">
				<h2>Current Weather</h2>
				<p>Current weather, forecasts, and alerts coming soon!</p>
			</section>

			<section className="secondary">
			</section>
		</section>
	)
}

export default About