import Navigation from './Navigation'
import PassHeading from './PassHeading'

const MMHeader = ({ loading }) => {

	return (
		<header>
			<PassHeading />
		</header>
	)
}

export default MMHeader