import { useRef, useState, useEffect } from 'react';
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useLogout from '../hooks/useLogout';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import * as React from 'react';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AiFillCar } from 'react-icons/ai'
import { MdCloud } from 'react-icons/md'
import { MdBlock } from 'react-icons/md'
import { BiTrafficCone } from 'react-icons/bi'

const UserReportPopup = ({ openReport, setOpenReport, data, setData }) => {

	const { auth } = useAuth();
	const reportOptions = auth?.options
	const passName = process.env.REACT_APP_PASS
	const axiosPrivate = useAxiosPrivate();

	const navigate = useNavigate()

	const [navBack, setNavBack] = useState(true)
	const [activeCategoryID, setActiveCategoryID] = useState()
	const [activeCategoryName, setActiveCategoryName] = useState()

	const [anchorEl, setAnchorEl] = useState()
	const open = Boolean(anchorEl);

	const [isReporting, setIsReporting] = useState(false)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleReport = () => {
		setAnchorEl(null)
		setOpenReport(true)
	}

	const handleCloseReport = () => {
		setOpenReport(false)
	}

	const goBack = () => {
		setNavBack(true)
		setActiveCategoryID()
	}

	const parent = ( item ) => {
		setNavBack(false)
		setActiveCategoryID(item.id)
		setActiveCategoryName(item.category_name)
	}

	const handleLoginSignup = () => {
		setOpenReport(false)
		navigate('/login')
	}

	const report = async ( opt ) => {
		const dismiss = () =>  toast.dismiss(userReportToast.current);
		const userReportToast = toast.loading("Submitting Report");
		const controller = new AbortController();
		setIsReporting(true)

		try {
			const response = await axiosPrivate.post('submit-report',
			JSON.stringify({report_type: 'pass', pass_name: passName, report_category: activeCategoryName, report: opt}),
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(userReportToast, { render: 'Thank you for your report!', type: 'success', isLoading: false, autoClose: 5000});

			// Update data context
			if (activeCategoryName == "closure"){
				const updatedClosures = response.data.pass_data.user_reports.user_closures
				setData({...data, pass_data: { ...data.pass_data, user_reports: { ...data.pass_data.user_reports, user_closures: updatedClosures }}})
			}

			if (activeCategoryName == "traffic"){
				const updatedTraffic = response.data.pass_data.user_reports.user_traffic
				setData({...data, pass_data: { ...data.pass_data, user_reports: { ...data.pass_data.user_reports, user_conditions: updatedTraffic }}})
			}

			if (activeCategoryName == "construction"){
				const updatedConstruction = response.data.pass_data.user_reports.user_construction
				setData({...data, pass_data: { ...data.pass_data, user_reports: { ...data.pass_data.user_reports, user_construction: updatedConstruction }}})
			}

			if (activeCategoryName == "conditions"){
				const updatedConditions = response.data.pass_data.user_reports.user_conditions
				setData({...data, pass_data: { ...data.pass_data, user_reports: { ...data.pass_data.user_reports, user_conditions: updatedConditions }}})
			}

			// On success
			setOpenReport(false)
			setNavBack(true)
			setActiveCategoryID()
			setIsReporting(false)

		} catch (err) {
			if (!err?.response) {
				toast.update(userReportToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(userReportToast, { render: 'Report Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
			setIsReporting(false)

		}
		return () => controller.abort();
	}

	const makeClass = (name) => {

		if (name === 'lane closure(s)'){
			return 'lane-closure'
		}
		return name.replace(/\s/g , "-")
	}

	return (
		<>
			<Dialog
				open={openReport}
				onClose={handleCloseReport}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="createReport"
			  >
				<DialogTitle id="alert-dialog-title">
				  {`Submit User Report for ${process.env.REACT_APP_PASS_NAME}`}
				</DialogTitle>
				<DialogContent>
					<>
						{ auth?.user ?

							reportOptions?.length ?
								<>
									<nav id="category" className={`${navBack ? '' : 'selected'}`}>
										{reportOptions.map((option, i) =>
											<button onClick={(e) => parent(option)} className={`parent ${activeCategoryID === option.id ? 'active' : ''}`} key={i}
												id={option.id}
											>
												{option.category_name === "traffic" && <AiFillCar />}
												{option.category_name === "construction" && <BiTrafficCone />}
												{option.category_name === "closure" && <MdBlock />}
												{option.category_name === "conditions" && <MdCloud />}
												{option.category_name}
												<nav id="levelTwo">
													{JSON.parse(option.options).map((opt, oi) =>
														<button disabled={isReporting ? true : false} className={makeClass(opt)} onClick={(e) => report(opt)} key={oi}>{opt}</button>
													)}
												</nav>
											</button>
										)}
										{ !navBack &&
											<button onClick={goBack}className="back">Back</button>
										}
											<button onClick={(e) => setOpenReport(false)}className="close">Close</button>
									</nav>
								</>
							:
							<p>No report options available</p>

							:
							<>
								<p className="reportNote">Help keep others informed of latest conditions. Login or signup now to add your own first hand reports.</p>
							</>
						}

					</>
				</DialogContent>
				<DialogActions>
					{ !auth?.user &&
						<>
							<button className="cancel" onClick={handleCloseReport}>Close</button>
							<button className="confirm" onClick={handleLoginSignup}>Signup/Login</button>
						</>
					}
				</DialogActions>
			</Dialog>

		</>
	)
}

export default UserReportPopup