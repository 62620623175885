import { useEffect } from "react"
import { ReactComponent as HighwaySign } from '../assets/images/dc_highway-sign-blank.svg'
import { Link } from "react-router-dom"
import { MdRefresh } from "react-icons/md"
import useAuth from '../hooks/useAuth';
import Tooltip from '@mui/material/Tooltip';
import anime from 'animejs/lib/anime.es.js';

const PassHeading = ({ refreshData, loading }) => {

	const { auth } = useAuth()

	useEffect(() => {
	}, [loading])

	return (
		<div id="passHeadingWrapper">
			<Link to="/" className="logo">
				<div className="icon"><HighwaySign /><span>{process.env.REACT_APP_ROAD}</span></div>
				<h1>{process.env.REACT_APP_PASS_NAME}</h1>
			</Link>
			{auth.user && <Tooltip title="Refresh Conditions"><span className={`refreshData ${loading ? 'updating' : ''}`} onClick={refreshData}><MdRefresh /></span></Tooltip>}
		</div>
	)
}

export default PassHeading