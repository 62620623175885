import { useOutletContext } from "react-router-dom";
import { useRef, useState, useEffect } from 'react';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MdClose } from 'react-icons/md';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { SuperSEO } from "react-super-seo";

const Cameras = () => {

	const [data, setData] = useOutletContext()
	const [cameras, setCameras] = useState()
	const [openLightbox, setOpenLightbox] = useState(false)

	const [cameraName, setCameraName] = useState()
	const [cameraMM, setCameraMM] = useState()
	const [cameraView, setCameraView] = useState()
	const [cameraImage, setCameraImage] = useState()


	useEffect(() => {
		setCameras(data.pass_data.cameras)
	}, [data])

	const handleCloseLightbox = () => {
		setOpenLightbox(false)
	}

	const handleCameraClick = (e, i, name, mm, view, image) => {
		setCameraName(name)
		setCameraMM(mm)
		setCameraView(view)
		setCameraImage(image)
		setOpenLightbox(true)
	}

	return (
		<section className="contentWrapper" id="cameras">

			<SuperSEO
			  title={process.env.REACT_APP_SEO_CAMERAS_TITLE}
			  description={process.env.REACT_APP_SEO_CAMERAS_DESCRIPTION}
			/>

			<section className="primary">
				<h2>Cameras ({cameras?.length})</h2>
				{cameras?.length ?
					<ul>
					{cameras.map((camera, i) => {
						let name = null
						let reg = /LVW|DMS/i
						if (camera.camera_name.includes(":")){
							const nameArray = camera.camera_name.split(':')
							name = nameArray[1].replace(reg, '')
						} else if (camera.camera_name.includes("@")){
							const nameArray = camera.camera_name.split('@')
							name = nameArray[1].replace(reg, '')
						} else {
							name = camera.camera_name.replace(reg, '')
						}
						return 	<>
									<li key={i} onClick={(e) => handleCameraClick(e, i, name, camera.camera_mile_marker, camera.camera_view, camera.camera_image_url)}>
										<h4>{name} (MM{camera.camera_mile_marker} - {camera.camera_view})</h4>
										<img src={camera.camera_image_url} alt={name} />
									</li>
								</>
					}
					)}
					</ul>
					:
					<p>no cameras available</p>
				}
			</section>

			<section className="secondary">
			</section>

			<Dialog
				open={openLightbox}
				onClose={handleCloseLightbox}
				fullWidth="true"
				maxWidth="md"
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				id="cameraLightbox"
			>
			  	<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleCloseLightbox}
							aria-label="close"
						>
							<MdClose />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							{`${cameraName} (MM${cameraMM} - ${cameraView})`}
						</Typography>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<img src={cameraImage} alt={cameraName} />
					</DialogContentText>
				</DialogContent>
			</Dialog>

		</section>
	)
}

export default Cameras