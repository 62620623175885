const Profile = () => {
	return (
		<section className="contentWrapper" id="profile">

			<section className="primary">
				<h2>Profile</h2>
			</section>

			<section className="secondary">
			</section>
		</section>
	)
}

export default Profile