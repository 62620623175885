import { useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ToastContainer, toast } from 'react-toastify';

const Settings = () => {

	const { auth, setAuth } = useAuth()
	const axiosPrivate = useAxiosPrivate();
	const [userSettings, setUserSettings] = useState(auth?.settings)

	const [cdotReports, setCdotReports] = useState(JSON.parse(userSettings.user_settings).cdot_feed)
	const [userReports, setUserReports] = useState(JSON.parse(userSettings.user_settings).user_feed)
	const [userClosures, setUserClosures] = useState(JSON.parse(userSettings.user_settings).user_feed_config.closures)
	const [userConditions, setUserConditions] = useState(JSON.parse(userSettings.user_settings).user_feed_config.conditions)
	const [confirmedThreshold, setConfirmedThreshold] = useState(JSON.parse(userSettings.user_settings).user_feed_config.confirm_threshold || 0)
	const [clearThreshold, setClearThreshold] = useState(JSON.parse(userSettings.user_settings).user_feed_config.clear_threshold || 1)

	const [savingSettings, setSavingSetting] = useState(false)

	useEffect(() => {
		console.log(auth)
	}, [auth])

	const handleSaveSettings = async (e) => {
		e.preventDefault()
		setSavingSetting(true)
		const dismiss = () =>  toast.dismiss(userSettingsToast.current);
		const userSettingsToast = toast.loading("Saving Settings");
		const controller = new AbortController();
		try {
			const response = await axiosPrivate.put('save-settings',
			JSON.stringify({cdot_feed: cdotReports, user_feed: userReports, closures: userClosures, conditions: userConditions, confirm_threshold: confirmedThreshold, clear_threshold: clearThreshold}),
			{
				signal: controller.signal
			});

			// Update Toast Notification
			toast.update(userSettingsToast, { render: 'Settings Saved', type: 'success', isLoading: false, autoClose: 5000});

			// Update State
			const updatedSettings = response?.data
			setAuth({...auth, settings: updatedSettings})
			setSavingSetting(false)
		} catch (err) {
			if (!err?.response) {
				toast.update(userSettingsToast, { render: 'No Server Response', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.update(userSettingsToast, { render: 'Save Settings Failed', type: 'error', isLoading: false, autoClose: 5000});
				{/* setErrMsg('Registration Failed'); */}
			}
			{/* errRef.current.focus(); */}
		}
		return () => controller.abort();
	}

	useEffect(() => {
	}, [userSettings])

	return (
		<section className="contentWrapper" id="settings">

			<section className="primary">
				<h2>Settings</h2>

				<form onSubmit={handleSaveSettings}>
					<FormControl component="fieldset" variant="standard">
						<FormLabel component="legend">Data Feeds</FormLabel>
						<FormGroup>
							<FormControlLabel
					    		control={
									<Switch disabled checked={cdotReports} onChange={(e) => setCdotReports(!cdotReports)} name="cdot" />
								}
								label="Official CDOT Reports"
							/>
							{/* <FormHelperText>Disabling the CDOT feed is not available.</FormHelperText> */}
							<FormControlLabel
								control={
									<Switch checked={userReports} onChange={(e) => setUserReports(!userReports)} name="user" />
								}
								label="User Reports"
							/>
							{ userReports ?

								<FormGroup id="userReportedSettings">
									<FormLabel component="legend">User Report Settings</FormLabel>
									<FormControlLabel
										control={
											<Switch checked={userClosures} onChange={(e) => setUserClosures(!userClosures)} name="closures" />
										}
										label="Closures"
									/>
									<FormControlLabel
										id="conditions"
										control={
											<Switch checked={userConditions} onChange={(e) => setUserConditions(!userConditions)} name="conditions" />
										}
										label="Conditions"
									/>
									<FormControl fullWidth>
										<InputLabel id="threshold-label">User Report Confirm Threshold</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={confirmedThreshold}
											label="User Report Confirm Threshold"
											onChange={(e) => setConfirmedThreshold(e.target.value)}
										>
											<MenuItem value="0">0 (Default)</MenuItem>
											<MenuItem value="1">1</MenuItem>
											<MenuItem value="2">2</MenuItem>
											<MenuItem value="3">3</MenuItem>
											<MenuItem value="4">4</MenuItem>
											<MenuItem value="5">5</MenuItem>
										</Select>
									</FormControl>
									<FormHelperText className="bottomMargin">Confirm threshold is the number of times a user report is confirmed before it will show up on your feed. For example if you select 0. As soon as someone reports something you will see it. If you select 3 you will not see a user report until 3 people confirm it to be true.</FormHelperText>

									<FormControl fullWidth>
										<InputLabel id="threshold-label">User Report Clear Threshold</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={clearThreshold}
											label="User Report Clear Threshold"
											onChange={(e) => setClearThreshold(e.target.value)}
										>
											<MenuItem value="1">1 (Default)</MenuItem>
											<MenuItem value="2">2</MenuItem>
											<MenuItem value="3">3</MenuItem>
											<MenuItem value="4">4</MenuItem>
											<MenuItem value="5">5</MenuItem>
										</Select>
									</FormControl>
									<FormHelperText>Clear threshold is the number of times a user report is cleared before it stops showing up on your feed. For example if you select 1. Once 1 person clears the report you will stop seeing it. Where as if you select 3 the alert may be cleared but it will still show up on your feed until 3 people clear it.</FormHelperText>


								</FormGroup>
								:
								null
							}
						</FormGroup>
					</FormControl>

					<button disabled={savingSettings ? true : false}>{savingSettings ? 'Saving Settings' : 'Save Settings'}</button>
				</form>
			</section>

			<section className="secondary">
			</section>

		</section>
	)
}

export default Settings