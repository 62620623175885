import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


interface LinkTabProps {
  label?: string;
  href?: string;
}

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
		  role="tabpanel"
		  hidden={value !== index}
		  id={`vertical-tabpanel-${index}`}
		  aria-labelledby={`vertical-tab-${index}`}
		  {...other}
		>
		  {value === index && (
			<Box sx={{ p: 3 }}>
			  <Typography>{children}</Typography>
			</Box>
		  )}
		</div>
	)
}

export default TabPanel