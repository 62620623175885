import { useNavigate } from 'react-router-dom'
import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';

const Unauthorized = () => {
	const navigate = useNavigate();

	const { auth, setAuth } = useAuth();

	useEffect(() => {
		console.log(auth)
	}, [auth])

	const goBack = () => navigate(-1);

	return (
		<section className="contentWrapper" id="alerts">

			<section className="primary">
				<h2>Unauthorized</h2>
				<h4>Hello {auth?.user?.first_name}</h4>
				<p>You do not have access to this page.</p>
				<div className="flexGrow">
					<button onClick={goBack}>Go Back</button>
				</div>
			</section>

			<section className="secondary">
			</section>
		</section>
	)
}

export default Unauthorized