import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Link, useNavigate } from 'react-router-dom';
import useRefreshToken from '../hooks/useRefreshToken';
import { ImBullhorn } from 'react-icons/im';
import { AiOutlineSearch } from 'react-icons/ai';
import { CgProfile } from 'react-icons/cg';
import { MdCreateNewFolder } from 'react-icons/md';
import { BiSupport } from 'react-icons/bi';
import { GoAlert } from 'react-icons/go'
import Login from '../components/Login'

import axios from '../api/axios';
const LOGIN_URL = 'login';

const EmailVerification = () => {

	const { auth, setAuth, persist, setPersist } = useAuth();
	const axiosPrivate = useAxiosPrivate();
	const refresh = useRefreshToken();

	const navigate = useNavigate()

	const params = new URLSearchParams(window.location.search);
	const url = params.get('url');

	const signature = params.get('signature');

	const verifyURL = url + "&signature=" + signature;

	const emailRef = useRef();
	const errRef = useRef();

	const [email, setEmail] = useState('');
	const [pwd, setPwd] = useState('');
	const [errMsg, setErrMsg] = useState('');

	const [emailMsg, setEmailMsg] = useState('');

	const [isVerifying, setIsVerifying] = useState(false)
	useEffect(() => {
		if (auth.user){
			const controller = new AbortController();

			const verifyEmailAddress = async () => {
				try {
					const response = await axiosPrivate.get(verifyURL, {
						signal: controller.signal
					});
					console.log(response.data);
					setEmailMsg(response.data.message);


				} catch(err) {
					console.log(err.message);
					setEmailMsg(err.message);
				}
			}

			verifyEmailAddress();

			return () => controller.abort();

		}
	},[auth])

	useEffect(() => {
		setErrMsg('');
	}, [email, pwd])

	useEffect(() => {
		localStorage.setItem('persist', persist);
	}, [persist])

	const togglePersist = () => {
		setPersist(prev => !prev);
	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsVerifying(true)

		try {
			const response = await axios.post(LOGIN_URL,
				JSON.stringify({email: email, password: pwd, remember_me: persist, first_login: true}),
				{
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json'
					},
					withCredentials: true
				}
			);
			// console.log(JSON.stringify(response?.data));
			//console.log(JSON.stringify(response));
			const accessToken = response?.data?.token;
			const user = response?.data?.user;
			const roles = response?.data?.user?.role;
			setAuth({ user, roles, accessToken });
			setEmail('');
			setPwd('');
			setIsVerifying(false)
		} catch (err) {
			if (!err?.response){
				setErrMsg('No Server Response');
			} else if ( err.response?.status === 400){
				setErrMsg('Missing username or password');
			} else if ( err.response?.status === 401){
				setErrMsg('Invalid Login');
			} else {
				setErrMsg('Login Failed')
			}
			errRef.current.focus();
			setIsVerifying(false)
		}
	}

	const goHome = () => {
		navigate("/")
	}

	return (
		<section className="contentWrapper" id="emailVerification">
			<section className="primary">
			{!auth.user && !url &&
				<article>
					<h2>Email Verification</h2>
					<p>You can't access this page directly. Please click the link in the verification email.</p>
				</article>
			}
			{!auth.user && url &&
				<article>
					<h2>Email Verification</h2>
					<p>Login to finish verifying your email.</p>
					<p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
					<h1>Sign In</h1>
					<form onSubmit={handleSubmit}>
						<label htmlFor="email">Email:</label>
						<input
							type="email"
							id="email"
							ref={emailRef}
							autoComplete="off"
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							required
						/>
						<label htmlFor="password">Password:</label>
						<input
							type="password"
							id="password"
							onChange={(e) => setPwd(e.target.value)}
							value={pwd}
							required
						/>
						<button disabled={isVerifying ? true : false}>{isVerifying ? 'Verifying Email' : 'Sign In'}</button>

						{/* <label htmlFor="persist">
						<input
							type="checkbox"
							id="persist"
							onChange={togglePersist}
							checked={persist}
						/>
						<span>Remember Me?</span>
						</label> */}
					</form>
				</article>
			}
			{auth.user && url &&
				<article>
					<p className="notification"><GoAlert /><span>{emailMsg}</span></p>
					<h2>Welcome to Drive Colorado!</h2>
					<p>You can now login and use your account. Your new login will work on this site, as well as any of the Drive Colorado pass websites.</p>
					<Login />
				</article>
			}
			</section>

			<section className="secondary">
			</section>
		</section>
	)
}

export default EmailVerification