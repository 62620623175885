import Login from '../components/Login'
import Register from '../components/Register'
import { useOutletContext } from "react-router-dom";
import { useRef, useState, useEffect } from 'react';
import { SuperSEO } from "react-super-seo";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TabPanel from '../components/TabPanel'
import { useMediaQuery } from 'react-responsive'

const dcColors = createTheme({
  palette: {
	primary: {
	  light: '#007e9b',
	  main: '#007e9b',
	  contrastText: '#007e9b',
	},
	contrastThreshold: 3,
	tonalOffset: 0,
  },
});

const LoginRegister = () => {

	const [loginToggle, setLoginToggle] = useState(false)

	const toggle = () => {
		setLoginToggle(!loginToggle)
	}

	const [tab, setTab] = useState(0)

	function a11yProps(index) {
	  return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	  };
	}

	const isMobileTabs = useMediaQuery({
		query: '(max-width: 600px)'
	})

	const handleTabChange = async (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	}

	return (
		<section className="contentWrapper" id="login">

			<SuperSEO
			  title={process.env.REACT_APP_SEO_LOGIN_TITLE}
			  description={process.env.REACT_APP_SEO_LOGIN_DESCRIPTION}
			/>

			<section className="primary">
				<ThemeProvider theme={dcColors}>
					<Tabs
						value={tab}
						onChange={handleTabChange}
						orientation={isMobileTabs ? "vertical" : "horizontal"}
					  >
						<Tab label="Login" {...a11yProps(0)} />
						<Tab label="Register" {...a11yProps(1)} />
					</Tabs>
				</ThemeProvider>

				<TabPanel value={tab} index={0}>
					<>
						<h2>Login</h2>
						<Login />
						<p>Don't have an account? <a href="#" onClick={(e) => setTab(1)}>Signup now!</a></p>
					</>
				</TabPanel>

				<TabPanel value={tab} index={1}>
					<>
						<h2>Register</h2>
						<Register />
						<p>Already have an account? <a href="#" onClick={(e) => setTab(0)}>Login now!</a></p>
					</>
				</TabPanel>
			</section>

			<section className="secondary">
			</section>
		</section>
	)
}

export default LoginRegister