import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';

const Error = () => {

	const { auth, setAuth } = useAuth();

	useEffect(() => {
		console.log(auth)
	}, [auth])

	return (
		<section className="contentWrapper" id="error">

			<section className="primary">
				<h2>404 Error</h2>
				<p>The page you are looking for can't be found.</p>
			</section>

			<section className="secondary">
			</section>
		</section>
	)
}

export default Error