import axios from 'axios';
// const BASE_URL = 'http://localhost:8000/v3';
// const BASE_URL = 'https://beta-api.drive-colorado.com/v3';
const BASE_URL = 'https://api.drive-colorado.com/v3';

export default axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json'
	}
});

export const axiosPrivate = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
	withCredentials: true
});


export const axiosBlank = axios.create({
	baseURL: '',
	headers: { 'Content-Type': 'application/json'},
});