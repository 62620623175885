import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Home from './routes/Home'
import Dashboard from './routes/Dashboard'
import Cameras from './routes/Cameras'
import History from './routes/History'
import Support from './routes/Support'
import Alerts from './routes/Alerts'
import About from './routes/About'
import OtherPasses from './routes/OtherPasses'
import Notifications from './routes/Notifications'
import News from './routes/News'
import Donate from './routes/Donate'
import Settings from './routes/Settings'
import Profile from './routes/Profile'
import LoginRegister from './routes/LoginRegister'
import Error from './routes/Error'
import RequireAuth from './components/RequireAuth';
import Unauthorized from './routes/Unauthorized'
import PersistLogin from './components/PersistLogin'
import ForgotPassword from './routes/ForgotPassword';
import PasswordReset from './routes/PasswordReset';
import EmailVerification from './routes/EmailVerification';
import Weather from './routes/Weather';


function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>

        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginRegister />} />
        <Route path="email-verification" element={<EmailVerification />} />

        {/* Pass Routes */}
        <Route path="/closure-history" element={<History />} />
        <Route path="/cameras" element={<Cameras />} />
        <Route path="/support" element={<Support />} />
        <Route path="/alerts" element={<Alerts />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/news-and-updates" element={<News />} />
        <Route path="/other-passes-and-routes" element={<OtherPasses />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/weather" element={<Weather />} />

        {/* Password Routes */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<PasswordReset />} />

        {/* Persist Login Routes */}
        <Route element={<PersistLogin />}>

            {/* Private Routes */}
            <Route element={<RequireAuth allowedRoles={[8675309,1]}/>}>
              <Route path="/user/dashboard" element={<Dashboard />} />
              <Route path="/user/settings" element={<Settings />} />
              <Route path="/user/profile" element={<Profile />} />
            </Route>

        </Route>

        {/* 404 Error */}
        <Route path="*" element={<Error />} />
        <Route path="/unauthorized" element={<Unauthorized />} />

      </Route>
    </Routes>
  );
}

export default App;
