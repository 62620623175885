import { useRef, useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from '../api/axios';
const PASSWORD_RESET_URL = 'forgot-password';

const ForgotPassword = () => {

	const emailRef = useRef();
	const errRef = useRef();

	const [email, setEmail] = useState('');
	const [errMsg, setErrMsg] = useState('');

	const [isResetting, setIsResetting] = useState(false)

	useEffect(() => {
		emailRef.current.focus();
	}, [])

	useEffect(() => {
		setErrMsg('');
	}, [email])

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsResetting(true)

		try {
			const response = await axios.post(PASSWORD_RESET_URL,
				JSON.stringify({email: email, route: process.env.REACT_APP_PASS}),
				{
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json'
					},
					withCredentials: true
				}
			);
			setIsResetting(false)

			toast.success("Email Sent!");
		} catch (err) {
			if (!err?.response){
				toast.error('No Server Response')
				{/* setErrMsg('No Server Response'); */}
			} else {
				toast.error('Reset Failed')
				{/* setErrMsg('Reset Failed') */}
			}
			{/* errRef.current.focus(); */}
		}
	}

	return (
		<section className="contentWrapper" id="forgotPassword">

			<section className="primary">
				<article className='inner'>
					<h2>Forgot Password?</h2>
					<p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
					<p>You will receive an email message with instructions on how to reset your password.</p>
					<form onSubmit={handleSubmit}>
						<label htmlFor="email">Email:</label>
						<input
							type="email"
							id="email"
							ref={emailRef}
							autoComplete="off"
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							required
						/>
						<button disabled={isResetting ? true : false}>{isResetting ? 'Sending Reset' : 'Reset Password'}</button>
					</form>
				</article>
			</section>

			<section className="secondary">
			</section>
		</section>
	)
}

export default ForgotPassword