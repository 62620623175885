import Loader from './Loader'

const Maintenance = ({ loading, passName, eta }) => {
	return (
		<section className="contentWrapper" id="pre">

			{loading ?
				<section className="primary" id="loading">
					<Loader />
					<h2>Getting current information for {passName}</h2>
				</section>
				:
				<section className="primary" id="maintenance">
					<h2>Drive Colorado is currently down for maintenance</h2>
					<p>Sorry for any inconvience we are working to get things back online as quickly as possible.</p>
				</section>
			}

		</section>
	)
}

export default Maintenance