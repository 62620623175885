import Navigation from './Navigation'
import PassHeading from './PassHeading'
import SecondaryNavigation from './SecondaryNavigation'

const Header = ({ loading, notifications, refreshData }) => {
	return (
		<header>
			<main>
				<PassHeading refreshData={refreshData} loading={loading} />
				{!loading && <Navigation />}
			</main>
			{!loading && <SecondaryNavigation notifications={notifications}/>}
		</header>
	)
}

export default Header