import { SuperSEO } from "react-super-seo";

const Donate = () => {
	return (
		<section className="contentWrapper" id="donate">

			<SuperSEO
			  title={process.env.REACT_APP_SEO_DONATE_TITLE}
			  description={process.env.REACT_APP_SEO_DONATE_DESCRIPTION}
			/>

			<section className="primary">
				<h2>Donate</h2>
				<p>Drive Colorado relies solely on donations to pay for overhead, including our server and development costs. We would love to continue providing quality services to locals and tourists and your donation will allow us to do so.
				</p>
				<p>Please consider donating. Donations are accepted via PayPal.</p>
				<a className="button" rel="noreferrer" href="https://www.paypal.com/donate/?hosted_button_id=3SPWYYV34SRSC" target="_blank" >Donate</a>
			</section>

			<section className="secondary">
			</section>
		</section>
	)
}

export default Donate