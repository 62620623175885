import useAuth from '../hooks/useAuth';
import useLogout from '../hooks/useLogout';

const Dashboard = () => {

	const { auth, setAuth } = useAuth();

	const exit = useLogout();

	const signOut = async () => {
		await exit();
		// navigate('/');
	}

	return (
		<section className="contentWrapper" id="dashboard">

			<section className="primary">
				<h2>Dashboard</h2>
				<p>Dashboard coming soon...</p>
			</section>

			<section className="secondary">
			</section>
		</section>
	)
}

export default Dashboard