import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa';
import axios from '../api/axios';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NAME_REGEX = /^[a-zA-Z ]{2,23}$/;

const Register = () => {
	const emailRef = useRef();
	const errRef = useRef();
	const fnameRef = useRef();

	const [fName, setFName] = useState('');
	const [validFName, setValidFName] = useState(false);
	const [fNameFocus, setFNameFocus] = useState(false);

	const [lName, setLName] = useState('');
	const [validLName, setValidLName] = useState(false);
	const [lNameFocus, setLNameFocus] = useState(false);

	const [email, setEmail] = useState('');
	const [validEmail, setValidEmail] = useState(false);
	const [emailFocus, setEmailFocus] = useState(false);

	const [pwd, setPwd] = useState('');
	const [validPwd, setValidPwd] = useState(false);
	const [pwdFocus, setPwdFocus] = useState(false);

	const [matchPwd, setMatchPwd] = useState('');
	const [validMatch, setValidMatch] = useState(false);
	const [matchFocus, setMatchFocus] = useState(false);

	const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);

	const [isRegistering, setIsRegistering] = useState(false)

	useEffect(() => {
		const result = EMAIL_REGEX.test(email);
		setValidEmail(result);
	}, [email])

	/* Check password against regex function on password change */
	useEffect(() => {
		const result = PWD_REGEX.test(pwd);
		setValidPwd(result);
		const match = pwd === matchPwd;
		setValidMatch(match);
	}, [pwd, matchPwd])

	/* Check First Name */
	useEffect(() => {
		const result = NAME_REGEX.test(fName);
		setValidFName(result);
	}, [fName])

	/* Check Last Name */
	useEffect(() => {
		const result = NAME_REGEX.test(lName);
		setValidLName(result);
	}, [lName])

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsRegistering(true)

		// Prevent Hacking of submit button by checking against regex again
		const v1 = EMAIL_REGEX.test(email);
		const v2 = PWD_REGEX.test(pwd);

		if (!v1 || !v2){
			setErrMsg("Invalid Entry");
			return;
		}

		try {
			const response = await axios.post('register',
				JSON.stringify({first_name: fName, last_name: lName, email, password: pwd, password_confirmation: matchPwd, route: process.env.REACT_APP_PASS}),
				{
					headers: { 'Content-Type': 'application/json'},
					withCredentials: true
				}
			);
			setSuccess(true);
			setIsRegistering(false)

		} catch (err) {
			if (!err?.response) {
				setErrMsg('No Server Response');
			} else if (err.response?.status === 409) {
				setErrMsg('Name Taken');
			} else if (err.response?.status === 422) {
				if (err.response?.data?.message){
					setErrMsg(err.response?.data?.message);
				} else {
					setErrMsg('Registration Failed - Error 422');
					// setErrMsg('Missing Required Field');
				}
			} else {
				setErrMsg('Registration Failed');
			}
			errRef.current.focus();
		}
	}

	return (
		<>
		{success ? (
			<article>
				<h1>Thanks for signing up with Drive Colorado!</h1>

				<p>A verification email has been sent to "{email}". Once you have verified your email address, you will be able to submit user reports and conditions!</p>
			</article>
		) : (
			<article>
				<p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
				<form onSubmit={handleSubmit}>

					{/* First Name */}
					<label htmlFor="fname">First Name:</label>
					<div className="inputWrapper">
						<span className={validFName ? "valid" : "hide"}><FaCheck /></span>
						<span className={validFName || !fName ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="text"
							id="fname"
							ref={fnameRef}
							className={validFName || !fName ? null : "error"}
							onChange={(e) => setFName(e.target.value)}
							required
							aria-invalid={validFName ? "false" : "true"}
							aria-describedby="fnamenote"
							onFocus={() => setFNameFocus(true)}
							onBlur={() => setFNameFocus(false)}
						/>
					</div>
					<p id="fnamenote" className={fNameFocus && fName && !validFName ? "instructions" : "offscreen"}>
						Invalid First Name.
					</p>

					{/* Last Name */}
					<label htmlFor="lname">Last Name:</label>
					<div className="inputWrapper">
						<span className={validLName ? "valid" : "hide"}><FaCheck /></span>
						<span className={validLName || !lName ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="text"
							id="lname"
							className={validLName || !lName ? null : "error"}
							onChange={(e) => setLName(e.target.value)}
							required
							aria-invalid={validLName ? "false" : "true"}
							aria-describedby="lnamenote"
							onFocus={() => setLNameFocus(true)}
							onBlur={() => setLNameFocus(false)}
						/>
					</div>
					<p id="lnamenote" className={lNameFocus && lName && !validLName ? "instructions" : "offscreen"}>
						Invalid Last Name.
					</p>


					{/* Email */}
					<label htmlFor="email">Email:</label>
					<div className="inputWrapper">
						<span className={validEmail ? "valid" : "hide"}><FaCheck /></span>
						<span className={validEmail || !email ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="email"
							id="email"
							className={validEmail || !email ? null : "error"}
							ref={emailRef}
							autoComplete="off"
							onChange={(e) => setEmail(e.target.value)}
							required
							aria-invalid={validEmail ? "false" : "true"}
							aria-describedby="uidnote"
							onFocus={() => setEmailFocus(true)}
							onBlur={() => setEmailFocus(false)}
						/>
					</div>
					<p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
						Invalid email address.
					</p>

					{/* Password */}
					<label htmlFor="password">Password:</label>
					<div className="inputWrapper">
						<span className={validPwd ? "valid" : "hide"}><FaCheck /></span>
						<span className={validPwd || !pwd ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="password"
							id="password"
							className={validPwd || !pwd ? null : "error"}
							onChange={(e) => setPwd(e.target.value)}
							value={pwd}
							required
							aria-invalid={validPwd ? "false" : "true"}
							aria-describedby="pwdnote"
							onFocus={() => setPwdFocus(true)}
							onBlur={() => setPwdFocus(false)}
						/>
					</div>
					<p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
						8 to 24 characters. Must include uppercase and lowercase letters, a number and a special character.	Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
					</p>

					{/* Password Confirmation */}
					<label htmlFor="confirm_pwd">Confirm Password:</label>
					<div className="inputWrapper">
						<span className={validMatch && matchPwd ? "valid" : "hide"}><FaCheck /></span>
						<span className={validMatch || !matchPwd ? "hide" : "invalid"}><FaExclamation /></span>
						<input
							type="password"
							id="confirm_pwd"
							className={validMatch || !matchPwd ? null : "error"}
							onChange={(e) => setMatchPwd(e.target.value)}
							value={matchPwd}
							required

						/>
					</div>
					<p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
						Must match the first password input field.
					</p>

					<button disabled={!validFName || !validLName || !validEmail || !validPwd || !validMatch || isRegistering ? true : false}>{isRegistering ? 'Registering' : 'Sign Up'}</button>
				</form>
			</article>
		)}
		</>
	)
}

export default Register