import axios from '../api/axios';
import useAuth from './useAuth';
import Cookies from 'js-cookie'

const useRefreshToken = () => {

	const { setAuth, persist } = useAuth();

	const refresh = async () => {

		try {

			const response = await axios.get('refresh', {
				withCredentials: true
			});

			const accessToken = response?.data?.token;
			const user = response?.data?.user;
			const roles = response?.data?.user?.role;
			persist && setAuth({ user, roles, accessToken });

			return response.data.token;
		} catch(err) {
			console.log(err);
			// If refresh fails remove can refresh cookie
			Cookies.remove("dc-can-refresh")
		}
	}
	return refresh;
}

export default useRefreshToken